.Header {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 35px;
  padding-bottom: 35px;
}

.Header.HeaderLeft {
  width: 215px;
}

.Header.HeaderRight {
  right: 0;
  left: auto;
  width: 285px;
}

.Header .MenuIcon {
  width: 215px;
  margin: 0;
  position: absolute;
  left: 0;
  top: 115px;
}

.Header .MenuIcon svg {
  cursor: pointer;
}

.Header > div {
  /*max-width: 1260px;*/
  padding: 0 35px;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
}

.Header .Logo {
  display: block;
  height: 45px;
  width: auto;
}

.Header .LogoContainer {
  cursor: pointer;
  width: 100px;
  display: inline-block;
  vertical-align: top;
}

.HeaderRight.Header > div {
  text-align: left;
  padding: 0 35px 0 0;
}

.Header.HeaderRight .MenuContainer {
  width: 100%;
}

.Header .MenuContainer {
  width: calc(100% - 100px);
  display: inline-block;
  vertical-align: top;
  text-align: right;
  color: #6f6f6f;
  line-height: 11px;
}

.Header .MenuIcon.Show svg {
}

.Header .MenuIcon svg g {
  stroke: #6f6f6f;
  /*transition: stroke 150ms linear;*/
}

.Header .MenuIcon svg:hover g {
  stroke: white;
}

.Header .MenuContainer svg,
.Header .MenuContainer div,
.Header .MenuContainer a,
.Header .MenuContainer span {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  line-height: 11px;
  height: 11px;
  font-size: 11px;
}

.Header .MenuContainer div:hover,
.Header .MenuContainer a:hover,
.Header .MenuContainer span:hover {
  color: white;
}

.Header .MenuContainer svg:hover g {
  stroke: white !important;
}

.Header .MenuContainer > span.lang {
  padding: 0 2px;
}

.Header .MenuContainer > span.langFirst {
  padding-left: 10px;
}

.Header .MenuContainer > span.langLast {
  padding-right: 0px;
}

.Header .MenuContainer > span,
.Header .MenuContainer > a {
  padding: 0 10px;
  font-size: 11px;
  line-height: 11px;
  display: inline-block;
}

.Header .MenuLogo {
  display: none;
  position: absolute;
  bottom: -42px;
  left: 35px;
  cursor: pointer;
}

.Header span.Active {
  color: black;
}
.Header span.Active.Dark {
  color: #4094bf;
}

@media screen and (max-width: 768px) {
  .Header {
    padding: 10px 0;
  }

  .Header > div {
    padding: 0 10px;
  }

  .Header .MenuLogo {
    left: 10px;
  }
}
