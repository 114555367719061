.Background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
}

.Background .Gradient {
  /*width: 100%;*/
  /*height: 100%;*/
  /*background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 100% 0%, hsla(196, 95%, 64%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 100% 99%, hsla(196, 95%, 64%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 0% 99%, hsla(196, 95%, 64%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 0% 0%, hsla(196, 95%, 64%, 1) 0, transparent 100%);*/

  /*background: linear-gradient(271deg, #56d8ff, #f1fcff, #a3e3fb, #daf3fd);*/
  /*background-size: 800% 800%;*/

  /*background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 100% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 100% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 0% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 0% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%);*/

  /*-webkit-animation: AnimationName 19s ease infinite;*/
  /*-moz-animation: AnimationName 19s ease infinite;*/
  /*animation: AnimationName 19s ease infinite;*/
}

@-webkit-keyframes AnimationName {
  0% {
    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%), radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),
      radial-gradient(at 100% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%), radial-gradient(at 100% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),
      radial-gradient(at 0% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%);
  }
  25% {
    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%), radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),
      radial-gradient(at 100% 0%, hsla(193, 100%, 97%, 1) 0, transparent 100%), radial-gradient(at 100% 99%, hsla(193, 100%, 97%, 1) 0, transparent 100%),
      radial-gradient(at 0% 99%, hsla(193, 100%, 97%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(193, 100%, 97%, 1) 0, transparent 100%);
  }
  50% {
    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%), radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),
      radial-gradient(at 100% 0%, hsla(196, 92%, 81%, 1) 0, transparent 100%), radial-gradient(at 100% 99%, hsla(196, 92%, 81%, 1) 0, transparent 100%),
      radial-gradient(at 0% 99%, hsla(196, 92%, 81%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(196, 92%, 81%, 1) 0, transparent 100%);
  }
  75% {
    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%), radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),
      radial-gradient(at 100% 0%, hsla(197, 90%, 92%, 1) 0, transparent 100%), radial-gradient(at 100% 99%, hsla(197, 90%, 92%, 1) 0, transparent 100%),
      radial-gradient(at 0% 99%, hsla(197, 90%, 92%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(197, 90%, 92%, 1) 0, transparent 100%);
  }
  100% {
    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%), radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),
      radial-gradient(at 100% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%), radial-gradient(at 100% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),
      radial-gradient(at 0% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%);
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%), radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),
      radial-gradient(at 100% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%), radial-gradient(at 100% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),
      radial-gradient(at 0% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%);
  }
  25% {
    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%), radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),
      radial-gradient(at 100% 0%, hsla(193, 100%, 97%, 1) 0, transparent 100%), radial-gradient(at 100% 99%, hsla(193, 100%, 97%, 1) 0, transparent 100%),
      radial-gradient(at 0% 99%, hsla(193, 100%, 97%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(193, 100%, 97%, 1) 0, transparent 100%);
  }
  50% {
    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%), radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),
      radial-gradient(at 100% 0%, hsla(196, 92%, 81%, 1) 0, transparent 100%), radial-gradient(at 100% 99%, hsla(196, 92%, 81%, 1) 0, transparent 100%),
      radial-gradient(at 0% 99%, hsla(196, 92%, 81%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(196, 92%, 81%, 1) 0, transparent 100%);
  }
  75% {
    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%), radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),
      radial-gradient(at 100% 0%, hsla(197, 90%, 92%, 1) 0, transparent 100%), radial-gradient(at 100% 99%, hsla(197, 90%, 92%, 1) 0, transparent 100%),
      radial-gradient(at 0% 99%, hsla(197, 90%, 92%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(197, 90%, 92%, 1) 0, transparent 100%);
  }
  100% {
    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%), radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),
      radial-gradient(at 100% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%), radial-gradient(at 100% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),
      radial-gradient(at 0% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%);
  }
}
@keyframes AnimationName {
  0% {
    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%), radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),
      radial-gradient(at 100% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%), radial-gradient(at 100% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),
      radial-gradient(at 0% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%);
  }
  25% {
    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%), radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),
      radial-gradient(at 100% 0%, hsla(193, 100%, 97%, 1) 0, transparent 100%), radial-gradient(at 100% 99%, hsla(193, 100%, 97%, 1) 0, transparent 100%),
      radial-gradient(at 0% 99%, hsla(193, 100%, 97%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(193, 100%, 97%, 1) 0, transparent 100%);
  }
  50% {
    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%), radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),
      radial-gradient(at 100% 0%, hsla(196, 92%, 81%, 1) 0, transparent 100%), radial-gradient(at 100% 99%, hsla(196, 92%, 81%, 1) 0, transparent 100%),
      radial-gradient(at 0% 99%, hsla(196, 92%, 81%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(196, 92%, 81%, 1) 0, transparent 100%);
  }
  75% {
    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%), radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),
      radial-gradient(at 100% 0%, hsla(197, 90%, 92%, 1) 0, transparent 100%), radial-gradient(at 100% 99%, hsla(197, 90%, 92%, 1) 0, transparent 100%),
      radial-gradient(at 0% 99%, hsla(197, 90%, 92%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(197, 90%, 92%, 1) 0, transparent 100%);
  }
  100% {
    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%), radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),
      radial-gradient(at 100% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%), radial-gradient(at 100% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),
      radial-gradient(at 0% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%);
  }
}
