@import '~antd/dist/antd.less';

.App {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .ContentContainer {
    width: calc(100% + 30px);
    height: 100%;
    z-index: 100;
    position: relative;
    padding-left: 230px;
    box-sizing: border-box;
    margin: 0 -15px;
    overflow-y: auto;
  }

  .ContentCol {
    padding-top: 156px;
    padding-left: 80px;
    width: 1200px;
    max-width: 100%;
    box-sizing: border-box;
    padding-bottom: 100px;

    .ContentWrapper {
      max-width: 760px;
    }

    h2 {
      font-size: 36px;
      line-height: 1;
      padding-bottom: 40px;
      margin: 0;
      font-weight: normal;
    }

    h3 {
      font-size: 14px;
      line-height: 18px;
      margin: 0;
    }

    p {
      line-height: 23px;
      font-weight: normal;
      padding-bottom: 50px;
      margin: 0;
    }

    div.Highlight {
      margin: 0 -20px;
      padding: 40px;
      position: relative;
      box-shadow: 0 2px 4px 0 rgba(169, 226, 255, 0.5);
      p {
        font-family: PingFangSC-Light, 'Microsoft Yahei', 'STHeiti Light', STXihei, SimHei, 'Heiti SC', 'Hiragino Sans GB', sans-serif;
        font-weight: lighter;
        font-size: 22px;
        line-height: 30px;
        z-index: 11;
        position: relative;
        padding-bottom: 0;
      }
      &:after {
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        backdrop-filter: blur(2px);
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    .PaddingTop50 {
      padding-top: 50px;
    }

    .PaddingTop130 {
      padding-top: 130px;
    }

    .PaddingTop100 {
      padding-top: 100px;
    }

    .PaddingTop80 {
      padding-top: 80px;
    }

    .PaddingBot60 {
      padding-bottom: 60px;
    }

    .PaddingBot80 {
      padding-bottom: 80px;
    }

    .PaddingBot36 {
      padding-bottom: 36px;
    }
  }
}

.Content {
  position: relative;
  z-index: 100;
}

@primary-color: #4094BF;@font-family: PingFangSC-Regular, sans-serif;