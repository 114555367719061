.GradientBackground {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: white;
}

.GradientBackground > div {
  display: block;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity linear 5000ms;
}

.GradientBackground > div.Bg0 {
  /*background-image: radial-gradient(at 50% 50%, hsla(182, 56%, 83%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 100% 0%, hsla(197, 75%, 58%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 0% 100%, hsla(197, 75%, 58%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 100% 100%, hsla(197, 75%, 58%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 0% 0%, hsla(197, 75%, 58%, 1) 0, transparent 100%);*/
  /*background-image: radial-gradient(at 50% 50%, hsla(170, 100%, 95%, 1) 0, transparent 89%),*/
  /*radial-gradient(at 50% 100%, hsla(196, 99%, 79%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 100% 0%, hsla(196, 99%, 79%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 0% 50%, hsla(196, 99%, 79%, 1) 0, transparent 55%),*/
  /*radial-gradient(at 100% 50%, hsla(196, 99%, 79%, 1) 0, transparent 55%),*/
  /*radial-gradient(at 100% 100%, hsla(204, 100%, 77%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 0% 100%, hsla(204, 100%, 77%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 0% 0%, hsla(196, 99%, 79%, 1) 0, transparent 100%);*/

  /*background-image: radial-gradient(at 50% 50%, hsla(170, 100%, 95%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 50% 100%, hsla(196, 99%, 79%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 100% 0%, hsla(196, 99%, 79%, 1) 0, transparent 100%),*/
  /*radial-gradient(at 0% 50%, hsla(196, 99%, 79%, 1) 0, transparent 55%),*/
  /*radial-gradient(at 100% 50%, hsla(196, 99%, 79%, 1) 0, transparent 55%),*/
  /*radial-gradient(at 100% 100%, hsla(204, 100%, 77%, 1) 0, transparent 55%),*/
  /*radial-gradient(at 0% 100%, hsla(204, 100%, 77%, 1) 0, transparent 55%),*/
  /*radial-gradient(at 0% 0%, hsla(196, 99%, 79%, 1) 0, transparent 55%);*/

  /*background-color:#ffffff;*/
  background-image: radial-gradient(at 50% 50%, hsla(170, 100%, 95%, 1) 0, transparent 100%), radial-gradient(at 50% 100%, hsla(196, 99%, 79%, 1) 0, transparent 100%),
    radial-gradient(at 100% 0%, hsla(196, 99%, 79%, 1) 0, transparent 100%), radial-gradient(at 0% 50%, hsla(196, 99%, 79%, 1) 0, transparent 100%),
    radial-gradient(at 100% 50%, hsla(196, 99%, 79%, 1) 0, transparent 100%), radial-gradient(at 100% 100%, hsla(204, 100%, 77%, 1) 0, transparent 100%),
    radial-gradient(at 0% 100%, hsla(204, 100%, 77%, 1) 0, transparent 100%), radial-gradient(at 0% 0%, hsla(196, 99%, 79%, 1) 0, transparent 100%);
}

.GradientBackground > div.Bg1 {
  background-image: radial-gradient(at 50% 50%, hsla(202, 100%, 87%, 1) 0, transparent 50%), radial-gradient(at 0% 50%, hsla(208, 100%, 78%, 1) 0, transparent 50%),
    radial-gradient(at 100% 49%, hsla(208, 100%, 78%, 1) 0, transparent 50%), radial-gradient(at 0% 100%, hsla(197, 76%, 58%, 1) 0, transparent 50%),
    radial-gradient(at 100% 100%, hsla(197, 75%, 58%, 1) 0, transparent 50%), radial-gradient(at 0% 0%, hsla(195, 100%, 43%, 1) 0, transparent 50%),
    radial-gradient(at 100% 0%, hsla(196, 99%, 44%, 1) 0, transparent 50%);
}

.GradientBackground > div.Bg2 {
  background-image: radial-gradient(at 50% 50%, hsla(196, 99%, 79%, 1) 0, transparent 100%), radial-gradient(at 100% 50%, hsla(195, 100%, 43%, 1) 0, transparent 100%),
    radial-gradient(at 0% 50%, hsla(195, 100%, 43%, 1) 0, transparent 100%), radial-gradient(at 100% 0%, hsla(195, 100%, 29%, 1) 0, transparent 50%),
    radial-gradient(at 0% 100%, hsla(195, 100%, 29%, 1) 0, transparent 50%), radial-gradient(at 100% 100%, hsla(195, 100%, 29%, 1) 0, transparent 50%),
    radial-gradient(at 0% 0%, hsla(195, 100%, 29%, 1) 0, transparent 50%);
}

.GradientBackground > div.Bg3 {
  background-image: radial-gradient(at 50% 50%, hsla(202, 100%, 87%, 1) 0, transparent 50%), radial-gradient(at 0% 50%, hsla(208, 100%, 78%, 1) 0, transparent 50%),
    radial-gradient(at 100% 49%, hsla(208, 100%, 78%, 1) 0, transparent 50%), radial-gradient(at 0% 100%, hsla(197, 76%, 58%, 1) 0, transparent 50%),
    radial-gradient(at 100% 100%, hsla(197, 75%, 58%, 1) 0, transparent 50%), radial-gradient(at 0% 0%, hsla(195, 100%, 43%, 1) 0, transparent 50%),
    radial-gradient(at 100% 0%, hsla(196, 99%, 44%, 1) 0, transparent 50%);
}

.GradientBackground.Gradient0 > div.Bg0 {
  opacity: 1;
}

.GradientBackground.Gradient1 > div.Bg1 {
  opacity: 1;
}

.GradientBackground.Gradient2 > div.Bg2 {
  opacity: 1;
}

.GradientBackground.Gradient3 > div.Bg3 {
  opacity: 1;
}

.GradientBackground.Gradient4 > div.Bg4 {
  opacity: 1;
}

.GradientBackground.Gradient5 > div.Bg5 {
  opacity: 1;
}

.GradientBackground.Gradient6 > div.Bg6 {
  opacity: 1;
}

/*.GradientBackground.Gradient::after {*/
/*    content: " ";*/
/*    display: block;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    z-index: 101;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    !*background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*!*/
/*    !*radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*!*/
/*    !*radial-gradient(at 100% 0%, hsla(196, 95%, 64%, 1) 0, transparent 100%),*!*/
/*    !*radial-gradient(at 100% 99%, hsla(196, 95%, 64%, 1) 0, transparent 100%),*!*/
/*    !*radial-gradient(at 0% 99%, hsla(196, 95%, 64%, 1) 0, transparent 100%),*!*/
/*    !*radial-gradient(at 0% 0%, hsla(196, 95%, 64%, 1) 0, transparent 100%);*!*/
/*    !*background: linear-gradient(271deg, #56d8ff, #f1fcff, #a3e3fb, #daf3fd);*!*/
/*    !*background-size: 800% 800%;*!*/

/*    background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*    radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*    radial-gradient(at 100% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*    radial-gradient(at 100% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*    radial-gradient(at 0% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*    radial-gradient(at 0% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%);*/

/*    -webkit-animation: AnimationName 19s ease infinite;*/
/*    -moz-animation: AnimationName 19s ease infinite;*/
/*    animation: AnimationName 19s ease infinite;*/

/*}*/

.GradientBackground > span {
  /*transform: scale(0.5, 2);*/
  /*transform-origin: top center;*/
  z-index: 100;
}

/*@-webkit-keyframes AnimationName {*/
/*    0%{*/
/*        background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%);*/
/*    }*/
/*    25%{*/
/*        background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 0%, hsla(193, 100%, 97%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 99%, hsla(193, 100%, 97%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 99%, hsla(193, 100%, 97%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 0%, hsla(193, 100%, 97%, 1) 0, transparent 100%);*/
/*    }*/
/*    50%{*/
/*        background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 0%, hsla(196, 92%, 81%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 99%, hsla(196, 92%, 81%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 99%, hsla(196, 92%, 81%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 0%, hsla(196, 92%, 81%, 1) 0, transparent 100%);*/
/*    }*/
/*    75%{*/
/*        background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 0%, hsla(197, 90%, 92%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 99%, hsla(197, 90%, 92%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 99%, hsla(197, 90%, 92%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 0%, hsla(197, 90%, 92%, 1) 0, transparent 100%);*/
/*    }*/
/*    100%{*/
/*        background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%);*/
/*    }*/
/*}*/
/*@-moz-keyframes AnimationName {*/
/*    0%{*/
/*        background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%);*/
/*    }*/
/*    25%{*/
/*        background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 0%, hsla(193, 100%, 97%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 99%, hsla(193, 100%, 97%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 99%, hsla(193, 100%, 97%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 0%, hsla(193, 100%, 97%, 1) 0, transparent 100%);*/
/*    }*/
/*    50%{*/
/*        background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 0%, hsla(196, 92%, 81%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 99%, hsla(196, 92%, 81%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 99%, hsla(196, 92%, 81%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 0%, hsla(196, 92%, 81%, 1) 0, transparent 100%);*/
/*    }*/
/*    75%{*/
/*        background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 0%, hsla(197, 90%, 92%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 99%, hsla(197, 90%, 92%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 99%, hsla(197, 90%, 92%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 0%, hsla(197, 90%, 92%, 1) 0, transparent 100%);*/
/*    }*/
/*    100%{*/
/*        background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%);*/
/*    }*/
/*}*/
/*@keyframes AnimationName {*/
/*    0%{*/
/*        background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%);*/
/*    }*/
/*    25%{*/
/*        background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 0%, hsla(193, 100%, 97%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 99%, hsla(193, 100%, 97%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 99%, hsla(193, 100%, 97%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 0%, hsla(193, 100%, 97%, 1) 0, transparent 100%);*/
/*    }*/
/*    50%{*/
/*        background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 0%, hsla(196, 92%, 81%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 99%, hsla(196, 92%, 81%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 99%, hsla(196, 92%, 81%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 0%, hsla(196, 92%, 81%, 1) 0, transparent 100%);*/
/*    }*/
/*    75%{*/
/*        background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 0%, hsla(197, 90%, 92%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 99%, hsla(197, 90%, 92%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 99%, hsla(197, 90%, 92%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 0%, hsla(197, 90%, 92%, 1) 0, transparent 100%);*/
/*    }*/
/*    100%{*/
/*        background-image: radial-gradient(at 40% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 60% 50%, hsla(0, 0%, 100%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 100% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 99%, hsla(194, 100%, 67%, 1) 0, transparent 100%),*/
/*        radial-gradient(at 0% 0%, hsla(194, 100%, 67%, 1) 0, transparent 100%);*/
/*    }*/
/*}*/
