.SearchContainer.Hidden {
  display: none;
}

.SearchContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100001;
  opacity: 0.85;
  background: #000000;
}

.SearchWrapper {
  display: flex;
  flex-direction: column;
  max-width: 1150px;
  margin: 0 auto;
  padding-top: 35px;
  height: 100%;

  .Divider {
    background: #ffffff;
    height: 1px;
    transform: scaleY(0.5);
    width: 100%;
    margin-top: 5px;
  }
}

.SearchInput {
  position: relative;

  .SearchIcon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    z-index: 100;
  }

  .CloseIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    z-index: 100;
  }

  input[type='search'] {
    user-select: text;
    z-index: 10;
    display: block;
    width: 100%;
    position: relative;
    line-height: 30px;
    height: 30px;
    font-size: 14px;
    background: none;
    border: none;
    color: white;
    padding: 0 36px;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-appearance: none;
    appearance: none;
  }

  input:focus {
    //-webkit-appearance: none;
    outline: none;
    box-shadow: none;
    border: none;
  }
}

.SearchGap {
  height: 64px;
}

.SearchContent {
  flex: 1;
  color: white;
  padding-right: 16px;
  // height: calc(100% - 150px);
  // overflow-x: hidden;
  overflow-y: auto;
  .SearchResultItem {
    padding-bottom: 30px;
    cursor: pointer;
  }
  .SearchResultItem:hover {
    .Title,
    .Url,
    .Desc {
      color: #8dcbeb;
      a:visited {
        color: #8dcbeb;
      }
    }
  }

  .Title {
    display: inline-block;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0.58px;
    .Divider {
      margin-top: 5px;
    }
    line-height: 18px;
    padding-bottom: 5px;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  .Url {
    a:hover {
      opacity: 0.5;
    }
    a:visited {
      color: #ffffff;
    }
    cursor: pointer;
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 0.49px;
    padding-bottom: 15px;
    display: block;
  }
  .Desc {
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 0.28px;
    text-align: justify;
    line-height: 16px;

    &Text {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      line-height: 16px;
      max-height: 32px;

      p {
        margin-bottom: 0;
      }

      figure {
        display: none;
      }
    }
  }
}

.SearchNoData {
  display: flex;
  align-items: center;
  padding-bottom: 120px;

  &Img {
    width: 55.07px;
    height: 55.07px;
    background: url('../assets/no_data.svg') center / cover no-repeat;
    flex: 0 0 auto;
  }

  &Main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 18px;
    font-weight: normal;
    color: #96ceeb;
    line-height: 27px;
    padding-left: 27px;
  }
}

@primary-color: #4094BF;@font-family: PingFangSC-Regular, sans-serif;