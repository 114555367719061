.SideBar {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 215px;
  padding-left: 35px;
  padding-top: 99px;
  opacity: 0;
  height: 100%;
  transition: opacity 500ms ease-in;
  font-size: 13px;
}

.SideBar * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.SideBar.FullHeightMode {
  opacity: 1;
}

.SideBar.FullHeightMode::after {
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.SideBar .MenuContainer {
  padding-top: 60px;
  z-index: 100;
  position: relative;
}

.SideBar .MenuContainer a {
  font-size: 12px;
  line-height: 15px;
  transition: color 150ms linear;
}
.SideBar .MenuContainer a:hover {
  color: white;
}
.SideBar.HoverWhite .MenuContainer a:hover {
  color: white;
}

.SideBar .MenuContainer > div {
  padding: 0 0 10px 0;
}

.SideBar .MenuContainer .SubMenu {
  padding-left: 10px;
  box-sizing: border-box;
}

.SideBar .MenuContainer .SubMenu > div {
  padding: 8px 0 0 0;
}
.SideBar .MenuContainer .SubMenu > .Active > a,
.SideBar.FullHeightMode .MenuContainer > .Active > a {
  color: white;
}
.SideBar .MenuContainer .SubMenu > .Active.Light > a,
.SideBar.FullHeightMode .MenuContainer > .Active.Light > a {
  color: black;
}
.SideBar .MenuContainer.Light .SubMenu > div > a,
.SideBar.FullHeightMode .MenuContainer.Light > div > a {
  color: #6f6f6f;
}
.SideBar .MenuContainer.Light .SubMenu > div.Active > a,
.SideBar.FullHeightMode .MenuContainer.Light > div.Active > a {
  color: black;
}

.v-en .SideBar .MenuContainer > div {
  padding-bottom: 8px;
}

.v-en .SideBar .MenuContainer a {
  line-height: 14px;
}
