.AnimatedBackground {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.AnimatedBackground > span {
  transform: scale(4, 4);
  transform-origin: top left;
}
