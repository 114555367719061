html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* chromium 滚动条定制 */
::-webkit-scrollbar {
  width: 4px;
  height: 6px;

  &-thumb {
    background-color: #b4b6b9;
  }

  &-track {
    background-color: #e6e6e6;
  }
}

#root {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  line-height: 1.5;
  font-family: PingFangSC-Regular, 'Microsoft Yahei', STHeiti, SimHei, 'Heiti SC', 'Hiragino Sans GB', sans-serif;
  font-weight: 400;
}

.FontNormal {
  font-family: PingFangSC-Regular, 'Microsoft Yahei', STHeiti, SimHei, 'Heiti SC', 'Hiragino Sans GB', sans-serif !important;
  font-weight: 400 !important;
}

.FontMedium {
  font-family: PingFangSC-Medium, 'Microsoft Yahei', STHeiti, SimHei, 'Heiti SC', 'Hiragino Sans GB', sans-serif;
  font-weight: 500;
}

.FontLighter {
  font-family: PingFangSC-Light, 'Microsoft Yahei', 'STHeiti Light', STXihei, SimHei, 'Heiti SC', 'Hiragino Sans GB', sans-serif;
  font-weight: lighter;
}

/*body.Loaded {*/
/*    background-color: black;*/
/*    background-image: linear-gradient(black, #042945);*/
/*}*/

body > div {
  position: relative;
}

body > div:first-child {
  z-index: 1;
}

body > div:last-child {
  z-index: 100000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.v-en {
  font-family: Arial, sans-serif;

  .FontNormal {
    font-family: Arial, sans-serif !important;
  }

  .FontMedium {
    font-family: Arial, sans-serif;
  }

  .FontLighter {
    font-family: Arial, sans-serif;
  }

  .FontPingFang {
    font-family: PingFangSC-Regular, 'Microsoft Yahei', STHeiti, SimHei, 'Heiti SC', 'Hiragino Sans GB', sans-serif !important;
  }

  .FontPingFangLighter {
    font-family: PingFangSC-Light, 'Microsoft Yahei', 'STHeiti Light', STXihei, SimHei, 'Heiti SC', 'Hiragino Sans GB', sans-serif;
    font-weight: lighter;
  }
}

@primary-color: #4094BF;@font-family: PingFangSC-Regular, sans-serif;